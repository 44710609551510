import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { MiniValidationScreen } from '@rossum/mini-validation-screen';
import { Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import {
  Redirect,
  Route as ReactRoute,
  RouteChildrenProps,
  Router,
  Switch,
} from 'react-router';
import { useDefaultListBacklink } from '../components/AnnotationInformation/components/useAnnotationBacklink';
import Announcements from '../components/Announcements';
import AppBar from '../components/AppBar';
import {
  EmbeddedModeGuard,
  NonAdminRestrictor,
  nonAdminRestrictorRoute,
  StatisticsRouteGuard,
} from '../components/Restrictors';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import Dashboard from '../containers/AnnotationList/Dashboard';
import { AutomationRoutes } from '../containers/Automation/AutomationRoutes';
import { automationPath } from '../containers/Automation/helpers';
import { ChangesSaved } from '../containers/ChangesSaved';
import { CHANGES_SAVED_PATH } from '../containers/ChangesSaved/constants';
import DocumentValidation from '../containers/DocumentValidation';
import IntegrationTest from '../containers/IntegrationTest';
import IntroScreen from '../containers/IntroScreen';
import Login from '../containers/Login';
import Maintenance from '../containers/Maintenance';
import Modals from '../containers/Modals';
import OrganizationLoader from '../containers/OrganizationLoader';
import PasswordConfirm from '../containers/Password/Confirm';
import PasswordReset from '../containers/Password/Reset';
import PersonalInfo from '../containers/PersonalInfo';
import { settingsPath } from '../containers/Settings/helpers';
import { SettingsRoutes } from '../containers/Settings/SettingsRoutes';
import Statistics from '../containers/Statistics';
import TimeExpired from '../containers/TimeExpired';
import { usersPath } from '../containers/Users/helpers';
import { WorkflowRoutes } from '../containers/WorkflowRoutes/WorkflowRoutes';
import RequireAuth from '../decorators/RequireAuth';
import { Billing } from '../features/billing';
import { EditDocument } from '../features/document-edit/EditDocument';
import DocumentList from '../features/document-list/DocumentList';
import { ErrorPage } from '../features/error-page';
import { QueueSettingsRoute } from '../features/queue-settings/routes/QueueSettingsRoute';
import { TaskContextProvider } from '../features/tasks/TaskContext';
import { TasksDialog } from '../features/tasks/TasksDialog';
import { useLogPageView } from '../lib/gtm';
import { history } from '../redux/configureStore';
import {
  fontFamilySelector,
  monoFontSelector,
} from '../redux/modules/organization/selectors';
import {
  enterLogin,
  enterQueue,
  enterQueueList,
  enterStatistics,
  enterValidation,
  exitQueue,
  leaveStatistics,
  leaveValidation,
} from '../redux/modules/ui/actions';
import { State } from '../types/state';
import { HelmetComponent } from './HelmetComponent';
import Route from './Route';
import styles from './style.module.sass';

type StateProps = {
  useMonoFont: boolean;
  fontFamily: string;
};

type DispatchProps = {
  enterLogin: () => void;
  enterQueue: () => void;
  enterQueueList: () => void;
  enterStatistics: () => void;
  enterValidation: () => void;
  exitQueue: () => void;
  leaveStatistics: () => void;
  leaveValidation: () => void;
};

type Props = StateProps & DispatchProps;

const Routes = ({
  enterLogin: _enterLogin,
  enterQueue: _enterQueue,
  enterQueueList: _enterQueueList,
  enterStatistics: _enterStatistics,
  enterValidation: _enterValidation,
  exitQueue: _exitQueue,
  leaveStatistics: _leaveStatistics,
  leaveValidation: _leaveValidation,
  useMonoFont,
  fontFamily,
}: Props) => {
  useLogPageView(history);
  useDefaultListBacklink(history);

  // the user will always be defined under RequireAuth hence this flag should always be used within the scope of RequireAuth

  return (
    <Router history={history}>
      <HelmetComponent />
      <Stack
        className={clsx(
          styles.BasepageContainerLayout,
          useMonoFont && 'monospace',
          'basepageContainerLayout'
        )}
        sx={{
          fontFamily,
          backgroundColor: 'background.default',
        }}
      >
        <Announcements />
        <AppBar />
        <div className={styles.BasepageContainer}>
          <Modals />
          <ReduxToastr
            preventDuplicates
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
          <Switch>
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/timeExpired" component={TimeExpired} />
            <Route path="/error" component={ErrorPage} />
            <Route path={CHANGES_SAVED_PATH} component={ChangesSaved} />
            <Route path="/recovery" component={PasswordReset} />
            <Route path="/resetPassword" component={PasswordConfirm} />
            <Route path="/integration-test" component={IntegrationTest} />
            <Route path="/mobileIntroScreen" component={IntroScreen} />
            <Route path="/organizationLoading" component={OrganizationLoader} />
            <Route exact path="/" component={Login} onEnter={_enterLogin} />
            <RequireAuth>
              <TaskContextProvider>
                <TasksDialog />
                <Switch>
                  <Route path="/documents" component={DocumentList} />
                  <Route path="/annotations/:queueId?" component={Dashboard} />
                  <Route path="/emails/:queueId?" component={Dashboard} />
                  <Route
                    onEnter={_enterValidation}
                    onExit={_leaveValidation}
                    path={[
                      '/document/:annotationId?',
                      '/embedded/document/:annotationId?',
                    ]}
                    render={() => (
                      <EmbeddedModeGuard>
                        <Switch>
                          <Route
                            path={[
                              '/document/:annotationId/edit',
                              '/embedded/document/:annotationId/edit',
                            ]}
                            component={EditDocument}
                          />
                          <Route>
                            {(
                              props: RouteChildrenProps<{
                                annotationId?: string;
                              }>
                            ) => {
                              return props.match?.params.annotationId ? (
                                <DocumentValidation {...props} />
                              ) : (
                                <Redirect to="/" />
                              );
                            }}
                          </Route>
                        </Switch>
                      </EmbeddedModeGuard>
                    )}
                  />
                  {DEV_FEATURES_ENABLED ? (
                    <Route
                      path={['/document-v2/:annotationId']}
                      render={({ match }) => (
                        <MiniValidationScreen
                          annotationId={Number(match.params.annotationId)}
                        />
                      )}
                    />
                  ) : null}
                  <Route path="/requests" component={WorkflowRoutes} />

                  {/* these routes are restricted for non admin users */}
                  <Route
                    path={[settingsPath(), automationPath()]}
                    render={routeProps => (
                      <NonAdminRestrictor
                        restrictComponent={<Redirect to="/documents" />}
                      >
                        <Switch>
                          <Route
                            path={automationPath()}
                            component={AutomationRoutes}
                          />
                          <Route
                            path={settingsPath()}
                            render={() => <SettingsRoutes {...routeProps} />}
                          />
                        </Switch>
                      </NonAdminRestrictor>
                    )}
                  />

                  <Route
                    path={['/users', '/users/:id']}
                    render={({ location }) => (
                      <Redirect
                        to={location.pathname.replace('/users', usersPath())}
                      />
                    )}
                  />
                  <ReactRoute path="/queues/:queueId/settings">
                    {nonAdminRestrictorRoute(routeProps => (
                      <QueueSettingsRoute {...routeProps} />
                    ))}
                  </ReactRoute>

                  <Route path="/account/:section" component={PersonalInfo} />
                  <Route path="/billing" component={Billing} />
                  <Route
                    path="/statistics"
                    onEnter={_enterStatistics}
                    onExit={_leaveStatistics}
                    render={props => (
                      <StatisticsRouteGuard>
                        <Statistics {...props} />
                      </StatisticsRouteGuard>
                    )}
                  />

                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </TaskContextProvider>
            </RequireAuth>
          </Switch>
        </div>
      </Stack>
    </Router>
  );
};

const mapDispatchToProps = {
  enterLogin,
  enterQueue,
  enterQueueList,
  enterStatistics,
  leaveStatistics,
  enterValidation,
  exitQueue,
  leaveValidation,
};

const mapStateToProps = (state: State) => ({
  useMonoFont: monoFontSelector(state),
  fontFamily: fontFamilySelector(state),
});

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
