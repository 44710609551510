import { SchemaDatapointEnumOption } from '@rossum/api-client/schemas';
import { Delete } from '@rossum/ui/icons';
import {
  Box,
  formControlClasses,
  LinearProgress,
  outlinedInputClasses,
  Stack,
} from '@rossum/ui/material';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellEditStartReasons,
  GridCellEditStopReasons,
  gridClasses,
  GridRowId,
  GridRowSelectionModel,
  GridValidRowModel,
  useGridApiRef,
} from '@rossum/ui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import update from 'immutability-helper';
import { fromPairs, get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Prompt, useHistory, useParams } from 'react-router';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { commonDataGridStyles } from '../../../../ui/data-grid/styles';
import { useDataGridLocaleText } from '../../../document-list/hooks/useDataGridLocaleText';
import { useFieldManagerData } from '../../hooks/useFieldManagerData';
import { usePatchSchemas } from '../../hooks/usePatchSchemas';
import { aggregationRow } from '../constants';
import { getFieldTitleFromPath } from '../routes';
import { filterPanelProps } from '../ui/dataGridStyles';
import { getAggregations } from './aggregations';
import { getColumns } from './columns/columns';
import { EnumAndFormulaCellValue, ExtendedCellParams } from './columns/types';
import { CustomGridCell } from './CustomGridCell';
import {
  batchEditingActive,
  cellEditabilityDisabled,
  dataGridStyles,
  rowHasError,
} from './dataGridStyles';
import { EditColumnDrawer } from './enum-formula-editing/EditColumnDrawer';
import { EnumDrawer } from './enum-formula-editing/EnumDrawer';
import { FormulaDrawer } from './enum-formula-editing/FormulaDrawer';
import { ErrorMessageWithDialog } from './errors/ErrorMessageWithDialog';
import { FieldDetailHeader } from './FieldDetailHeader';
import { getProcessRowUpdate, UpdatedSchemasMap } from './getProcessRowUpdate';
import { useCopyPasteFieldSettings } from './hooks/useCopyPasteFieldSettings';
import { useDuplicateFieldToQueues } from './hooks/useDuplicateFieldToQueues';
import { useLeaveSafelyDialog } from './hooks/useLeaveSafelyDialog';
import { useRemoveFromQueues } from './hooks/useRemoveFromQueues';
import { removeItemFromSchema } from './removeItemFromSchema';
import { transformSchemasToRows } from './rows/rows';
import { GridRowModel, GridRowModelKeys } from './rows/rowTypes';
import { SelectionPanel } from './selection/SelectionPanel';

export const FieldDetail = () => {
  const params = useParams<{ schemaId: string }>();
  const intl = useIntl();
  const { schemaId } = params;
  const history = useHistory();
  const localeText = useDataGridLocaleText();

  const { flatSchemasWithQueues, schemas, queues, workspaces, isLoading } =
    useFieldManagerData();

  const [rows, setRows] = useState<Array<GridRowModel>>(
    transformSchemasToRows(schemaId, flatSchemasWithQueues)
  );

  const [optionsForEnumDialog, setOptionsForEnumDialog] = useState<{
    options: SchemaDatapointEnumOption[];
    value: EnumAndFormulaCellValue;
  } | null>(null);

  const [formulaForFormulaDrawer, setFormulaForFormulaDrawer] = useState<{
    formula: string;
    value: EnumAndFormulaCellValue;
  } | null>(null);

  const [dataForBulkEditDrawer, setDataForBulkEditDrawer] = useState<{
    // Bulk edit is available only for options and formula for now
    field: Extract<GridRowModelKeys, 'options' | 'formula'>;
    aggregations?: Set<string>;
    rows?: GridRowModel[];
  } | null>(null);

  // I would like to find a nicer solution to an issue when rows are empty after doing refresh on a detail page
  // but this one is functional
  useEffect(() => {
    const updatedRows = transformSchemasToRows(
      schemaId,
      flatSchemasWithQueues
    )();
    setRows(updatedRows);
  }, [schemaId, flatSchemasWithQueues]);

  const [updatedSchemas, setUpdatedSchemas] = useState<UpdatedSchemasMap>({});

  const schemasToSave = Object.values(updatedSchemas);
  const schemasToSaveLength = schemasToSave.length;
  const hasNoChanges = schemasToSaveLength === 0;

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const selectionActive = rowSelectionModel.length > 0;

  const selectedRows = useMemo(
    () => rows.filter(row => rowSelectionModel.includes(row.meta.schema_id)),
    [rows, rowSelectionModel]
  );

  const [editingField, setEditingField] = useState<string | null>(null);

  const shouldWarnUser = !(editingField === null && hasNoChanges);

  const { leaveSafelyDialog, setDialogState, dialogState } =
    useLeaveSafelyDialog();

  const gridApiRef = useGridApiRef();

  const queryClient = useQueryClient();

  const processRowUpdate = useCallback(
    (next: GridRowModel, prev: GridRowModel) => {
      const processUpdate = getProcessRowUpdate({
        setRows,
        setUpdatedSchemas,
        schemas: flatSchemasWithQueues,
      });

      const shouldBatchUpdate = selectionActive && editingField !== null;

      if (shouldBatchUpdate) {
        gridApiRef.current
          .getSelectedRows()
          // @ts-expect-error GridValidRowModel returned from getSelectedRows is less strict version of GridRowModel
          .forEach(row => processUpdate(row));

        setEditingField(null);
      }

      // processRowUpdate must return updated row
      return processUpdate(next, prev);
    },
    [flatSchemasWithQueues, selectionActive, editingField, gridApiRef]
  );

  const removeFromSchema = useCallback(
    (row: GridRowModel) => {
      const { schema_id } = row.meta;

      if (schema_id === aggregationRow) {
        return;
      }

      setUpdatedSchemas(prevState => {
        const alreadyInUpdated = prevState[schema_id];
        const originalSchema = flatSchemasWithQueues.find(
          schema => schema.id === schema_id
        );
        const schemaToUpdate = alreadyInUpdated ?? originalSchema;

        if (!schemaToUpdate) {
          return prevState;
        }

        const schemaWithoutRemoved = removeItemFromSchema(schemaToUpdate, row);

        return {
          ...prevState,
          [schema_id]: schemaWithoutRemoved,
        };
      });

      setRows(prevState =>
        prevState.filter(r => r.meta.schema_id !== schema_id)
      );
    },
    [flatSchemasWithQueues]
  );

  const { mutation, taskQueue } = usePatchSchemas();

  const handleSubmitChanges = () => {
    mutation.mutate(schemasToSave, {
      onSuccess: ({ fulfilled }) => {
        setUpdatedSchemas(prev =>
          update(prev, { $unset: fulfilled.map(({ id }) => id) })
        );

        fulfilled.forEach(schema => {
          queryClient.setQueryData(['schema', schema.url], schema);
        });
      },
    });
  };

  const { setRemoveDialogParams, removeDialog } = useRemoveFromQueues({
    removeFromSchema,
  });

  const { setDuplicateDialogParams, duplicateDialog } =
    useDuplicateFieldToQueues({
      setUpdatedSchemas,
      setRows,
      queues,
      workspaces,
      flatSchemasWithQueues,
      schemas,
      updatedSchemas,
    });

  const { copiedRow, setCopiedRow, pasteFieldConfigurationToRows } =
    useCopyPasteFieldSettings({
      flatSchemasWithQueues,
      queues,
      schemas,
      updatedSchemas,
      setUpdatedSchemas,
      setRows,
    });

  const visibleColumnsPerRows = useMemo(
    () =>
      getColumns({
        openRemoveModal: setRemoveDialogParams,
        setOptionsForEnumDialog,
        setFormulaForFormulaDrawer,
        openDuplicateModal: setDuplicateDialogParams,
        setCopiedRow,
        copiedRow,
        pasteFieldConfigurationToRows,
        intl,
      }).filter(
        ({ editabilityCondition }) =>
          editabilityCondition === undefined ||
          rows.some(row => editabilityCondition(row.meta))
      ),
    [
      setRemoveDialogParams,
      setDuplicateDialogParams,
      setCopiedRow,
      copiedRow,
      pasteFieldConfigurationToRows,
      intl,
      rows,
    ]
  );

  const pinnedRows = useMemo(
    () =>
      rows.length === 0
        ? undefined
        : {
            bottom: [
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              {
                // returned value from getAggregations is of a different type than GridRowModel
                // and getting of values for Aggregations row is tailored for its needs
                ...getAggregations(visibleColumnsPerRows, rows),
                id: '0',
                meta: {
                  schema_id: aggregationRow,
                  path: [],
                },
              } as unknown as GridRowModel,
            ],
          },
    [rows, visibleColumnsPerRows]
  );

  const editableColumnsForSelectedRows = useMemo(() => {
    if (selectedRows.length === 0) {
      return [];
    }

    return visibleColumnsPerRows.flatMap(column => {
      const { editable, editabilityCondition, field } = column;

      // "options" and "formula" fields are special, because they are not editable in the table cell itself, but only batchEditable from the selection panel
      if (field === 'options' || field === 'formula') {
        return {
          ...column,
          isBatchEditable: editabilityCondition
            ? selectedRows.every(row => editabilityCondition(row.meta))
            : false,
        };
      }

      return editable
        ? {
            ...column,
            isBatchEditable: editabilityCondition
              ? selectedRows.every(row => editabilityCondition(row.meta))
              : !!column.editable,
          }
        : [];
    });
  }, [selectedRows, visibleColumnsPerRows]);

  const selectedRowIds = useMemo(
    () => selectedRows.map(row => row.meta.schema_id),
    [selectedRows]
  );

  const [batchEditError, setBatchEditError] = useState<boolean>(false);
  const prevRowValues = useRef<GridValidRowModel[] | null>(null);
  const startBatchEditMode = useCallback(
    ({ id, field }: { id: GridRowId; field: string }) => {
      prevRowValues.current = Array.from(
        gridApiRef.current.getSelectedRows().values()
      );
      setEditingField(field);

      gridApiRef.current.startCellEditMode({
        id,
        field,
      });
    },
    [gridApiRef]
  );

  const revertBatchEdits = useCallback(() => {
    if (prevRowValues.current !== null) {
      gridApiRef.current.updateRows(prevRowValues.current);
    }
    setBatchEditError(false);
    setEditingField(null);
  }, [gridApiRef]);

  const mutationErrorIds = useMemo(
    () =>
      mutation.data?.rejected.map(rejected => {
        const id = get(rejected, 'id');
        return id as number | undefined;
      }) ?? [],
    [mutation.data?.rejected]
  );

  // synchronize state after schemas update
  // using reset with changing key cannot be used because we want to preserve other states (such as selection, sorting etc.)
  useEffect(() => {
    if (mutationErrorIds !== undefined) {
      setRows(prevRows => {
        const newRows = transformSchemasToRows(
          schemaId,
          flatSchemasWithQueues
        )();
        const prevRowsMap = fromPairs(
          prevRows.map(row => [row.meta.schema_id, row])
        );

        return newRows.map(newRow => {
          if (newRow.meta.schema_id === 'aggregation-row') {
            return newRow;
          }

          return mutationErrorIds.includes(newRow.meta.schema_id)
            ? // keep previous row state in case of the error
              prevRowsMap[newRow.meta.schema_id] ?? newRow
            : newRow;
        });
      });
    }
  }, [flatSchemasWithQueues, schemaId, mutationErrorIds]);

  const fieldTitle = getFieldTitleFromPath(history.location.pathname);

  return (
    <PageLayoutV2
      fullWidth
      renderHeader={params => (
        <FieldDetailHeader
          mutationIsLoading={mutation.isLoading}
          taskQueue={taskQueue}
          schemasToSaveLength={schemasToSaveLength}
          handleSubmitChanges={handleSubmitChanges}
          {...params}
        />
      )}
    >
      {fieldTitle ? (
        <HelmetComponent
          dynamicName={fieldTitle}
          translationKey="features.routes.pageTitles.settings.fieldManager.details"
        />
      ) : null}
      <Stack px={4} py={4}>
        {/* Box is needed to prevent screen from jumping when SelectionPanel changes visibility */}
        <Box>
          <SelectionPanel
            visible={selectionActive}
            selectedRows={selectedRows}
            setRowSelectionModel={setRowSelectionModel}
            editableColumns={editableColumnsForSelectedRows}
            startBatchEditMode={startBatchEditMode}
            openDeleteModal={setRemoveDialogParams}
            gridApiRef={gridApiRef}
            shouldDisplayPasteOption={!!copiedRow}
            pasteFieldConfigurationToRows={pasteFieldConfigurationToRows}
            setDataForBulkEditDrawer={setDataForBulkEditDrawer}
          />
        </Box>

        <Stack spacing={2} direction="row" alignItems="center">
          {(mutation.isError || !!mutation.data?.rejected.length) && (
            <ErrorMessageWithDialog
              rejectedRequests={mutation.data?.rejected}
              schemas={flatSchemasWithQueues}
            />
          )}
        </Stack>

        <Box sx={{ ...dataGridStyles, position: 'relative', height: 1 }}>
          <DataGridPro<GridRowModel>
            disableColumnSelector
            apiRef={gridApiRef}
            columns={visibleColumnsPerRows}
            localeText={localeText}
            rows={rows}
            loading={isLoading}
            disableColumnReorder
            disableRowSelectionOnClick
            sx={{
              ...commonDataGridStyles,
              [`.${gridClasses['pinnedRows--bottom']}`]: {
                position: 'fixed',
              },
            }}
            hideFooter
            checkboxSelection
            onRowSelectionModelChange={newRowSelectionModel => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            initialState={{
              pinnedColumns: {
                right: ['actions'],
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
              },
            }}
            rowSelectionModel={rowSelectionModel}
            disableColumnMenu={selectionActive}
            processRowUpdate={processRowUpdate}
            getRowId={row => row.meta.schema_id}
            pinnedRows={pinnedRows}
            slots={{
              cell: CustomGridCell,
              filterPanelDeleteIcon: Delete,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              cell: {
                selectionActive,
                setBatchEditError,
                onVariantsClick: (
                  field: Extract<GridRowModelKeys, 'options' | 'formula'>,
                  aggregations: Set<string>
                ) => setDataForBulkEditDrawer({ field, aggregations }),
              },
              baseSelectOption: {
                // @ts-expect-error sx is not in the type definition but it works,
                sx: {
                  // workaround for making first empty option having the same height as other options
                  '&:after': { content: "' '" },
                },
              },
              baseFormControl: {
                sx: {
                  // workaround for making singleSelect input filter full width
                  [`> * .${formControlClasses.root}`]: {
                    width: '100%',
                  },
                },
              },

              baseSelect: {
                native: false,
                sx: {
                  [`.${gridClasses.cell} & .${outlinedInputClasses.notchedOutline}`]:
                    {
                      border: 'unset !important',
                    },

                  [`& .${outlinedInputClasses.input}`]: {
                    pl: 2,
                  },
                },
              },
              filterPanel: filterPanelProps,
            }}
            onCellEditStop={({ reason, id, field }, e) => {
              if (
                selectionActive &&
                reason === GridCellEditStopReasons.escapeKeyDown
              ) {
                revertBatchEdits();
                return;
              }

              // revert edits on "blur" in case there is an error
              if (
                batchEditError &&
                reason !== GridCellEditStopReasons.enterKeyDown
              ) {
                e.defaultMuiPrevented = true;
                gridApiRef.current.stopCellEditMode({
                  id,
                  field,
                  ignoreModifications: true,
                });
                revertBatchEdits();
              }
            }}
            // onCellEditStart is not triggered when using gridApiRef.startCellEditMode
            onCellEditStart={({ reason, id, field }, e) => {
              // do not start edit on printable keydown - it breaks usage of Autocomplete because value is not an array
              if (reason === GridCellEditStartReasons.printableKeyDown) {
                e.defaultMuiPrevented = true;
                return;
              }

              if (selectionActive) {
                if (
                  // do not start edit when batch editing is already active
                  editingField !== null ||
                  // do not start edit when cell is not editable
                  !editableColumnsForSelectedRows.some(
                    c => c.field === field && c.isBatchEditable
                  ) ||
                  // do not start edit when cell is not in selected row
                  !selectedRowIds.includes(Number(id))
                ) {
                  e.defaultMuiPrevented = true;
                  return;
                }

                // enter batch edit mode on focused cell
                if (editingField === null) {
                  e.defaultMuiPrevented = true;

                  startBatchEditMode({ id, field });
                }
              }
            }}
            isCellEditable={(params: ExtendedCellParams) =>
              params.id !== aggregationRow &&
              // control editability of cells based on the condition from column definition
              (params.colDef.editabilityCondition?.(params.row.meta) ?? true)
            }
            getRowClassName={({ id }) =>
              id && mutationErrorIds?.includes(Number(id)) ? rowHasError : ''
            }
            getCellClassName={(params: ExtendedCellParams) => {
              if (
                selectedRowIds.includes(Number(params.id)) &&
                params.field === editingField
              ) {
                return batchEditingActive;
              }

              // highlight originally editable cells that are not editable due to editabilityCondition
              return params.colDef?.editable &&
                !params.isEditable &&
                params.id !== aggregationRow
                ? cellEditabilityDisabled
                : '';
            }}
          />
          {removeDialog}
          {leaveSafelyDialog}
          <EnumDrawer
            onClose={() => setOptionsForEnumDialog(null)}
            optionsForEnumDialog={optionsForEnumDialog}
          />
          <FormulaDrawer
            onClose={() => setFormulaForFormulaDrawer(null)}
            formulaForDrawer={formulaForFormulaDrawer}
          />
          {dataForBulkEditDrawer ? (
            <EditColumnDrawer
              key={dataForBulkEditDrawer.field}
              onClose={() => setDataForBulkEditDrawer(null)}
              dataForBulkEditDrawer={dataForBulkEditDrawer}
              setUpdatedSchemas={setUpdatedSchemas}
              setRows={setRows}
              rows={rows}
              flatSchemasWithQueues={flatSchemasWithQueues}
            />
          ) : null}
          {duplicateDialog}
        </Box>
      </Stack>
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={shouldWarnUser}
      />
    </PageLayoutV2>
  );
};
