import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { SettingsBreadcrumbs } from '../../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps } from '../../../../ui/header/Header';
import { FIELD_FORM_ID } from '../../../queue-settings/fields/constants';
import { fieldManagerPath } from '../../constants';

type CreateFieldHeaderProps = { isLoading: boolean; goBack: () => void } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const CreateFieldHeader = ({
  isLoading,
  goBack,
  ...rest
}: CreateFieldHeaderProps) => {
  const intl = useIntl();

  return (
    <Header
      {...rest}
      breadcrumbs={
        <SettingsBreadcrumbs
          breadcrumbs={[
            {
              to: fieldManagerPath(),
              label: intl.formatMessage({
                id: 'features.fieldManager.overview.ui.header.title',
              }),
            },
            {
              label: intl.formatMessage({
                id: 'features.fieldManager.CreateFieldForm.title',
              }),
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'features.fieldManager.CreateFieldForm.title',
      })}
      description={null}
      buttons={[
        <Button
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
          key="fm-create-field-btn"
          data-cy="fm-create-field-btn"
          startIcon={
            isLoading && <CircularProgress size={20} color="secondary" />
          }
        >
          {/* TODO: Might not be final label  */}
          {intl.formatMessage({
            id: 'features.fieldManager.CreateFieldForm.submitButton',
          })}
        </Button>,
      ]}
      onBackButtonClicked={goBack}
    />
  );
};
