import {
  ExtensionOutlined,
  HowToReg,
  ManageSearch,
  Settings,
  StoreMallDirectoryRounded,
} from '@rossum/ui/icons';
import {
  IconGoGame,
  IconTag,
  IconUserSquareRounded,
} from '@rossum/ui/icons/tabler';
import { SvgIcon } from '@rossum/ui/material';
import { Location } from 'history';
import { isEmpty, pick } from 'lodash';
import AlphaABoxOutlineIcon from 'mdi-react/AlphaABoxOutlineIcon';
import BrainIcon from 'mdi-react/BrainIcon';
import FolderHomeIcon from 'mdi-react/FolderHomeIcon';
import StatisticsIcon from 'mdi-react/GoogleAnalyticsIcon';
import GoogleCirclesExtendedIcon from 'mdi-react/GoogleCirclesExtendedIcon';
import RobotIcon from 'mdi-react/RobotIcon';
import { NavLinkProps } from 'react-router-dom';
import { getDashboardPath } from '../../containers/AnnotationList/helpers';
import {
  automationEnginesPath,
  automationPath,
} from '../../containers/Automation/helpers';
import { parse, stringify } from '../../lib/url';
import { STATISTICS_QUERY } from '../../redux/modules/localStorage/actions';
import { GroupRole } from '../../types/group';
import { Organization } from '../../types/organization';

type VisibleMenuItemsForTabContext = {
  organizationFeatures: Organization['uiSettings']['features'];
  customFeatures: {
    isLabelsEnabled: boolean;
    isFieldManagerEnabled: boolean;
  };
};

type MenuItemDefinition<Name extends string, ParentName extends string> = {
  name: Name;
  link: string;
  icon: JSX.Element;
  isVisible?: (context: VisibleMenuItemsForTabContext) => boolean;
  dataCy: string;
  translationKey: `${ParentName}.${Name}`;
  isActive?: NavLinkProps['isActive'];
  beta?: boolean;
};

export type LinkContext = { shouldDisplayNewDashboard?: boolean };

type TabDefinition<
  MName extends string,
  Name extends string,
  MenuItems extends Array<MenuItemDefinition<MName, Name>>,
> = {
  name: Name;
  icon: JSX.Element;
  roles?: GroupRole[];
  menu?: MenuItems;
  link: string | ((context?: LinkContext) => string);
  isSelected?: (location: Location<unknown>) => boolean;
};

type HasMenu<T> =
  T extends TabDefinition<string, string, infer M>
    ? M extends []
      ? never
      : T
    : never;

// Helper functions that are used only to infer correct types
const tab = <
  MN extends string,
  N extends string,
  // WORKAROUND: [] is used instead of undefined until we have strict mode turned on
  M extends Array<MenuItemDefinition<MN, N>> = [],
>(
  t: TabDefinition<MN, N, M>
) => t;
const menu = <N extends string, P extends string>(
  m: MenuItemDefinition<N, P>
) => m;

export const tabs = [
  tab({
    name: 'documents',
    icon: <FolderHomeIcon />,
    link: () => getDashboardPath(),
    isSelected: location =>
      Boolean(location.pathname.match(/^\/annotations|^\/emails|^\/documents/)),
  }),
  tab({
    name: 'requests',
    icon: <HowToReg />,
    link: '/requests',
    isSelected: location => Boolean(location.pathname.match(/^\/requests/)),
  }),
  tab({
    name: 'automation',
    link: automationPath(),
    roles: ['admin', 'organization_group_admin'],
    icon: <RobotIcon />,
    menu: [
      menu({
        name: 'automationSettings',
        link: automationPath(),
        icon: <AlphaABoxOutlineIcon size={18} />,
        dataCy: 'settings-sidebar-automation-link',
        translationKey: 'automation.automationSettings',
      }),
      menu({
        name: 'aiEngines',
        link: automationEnginesPath(),
        icon: <BrainIcon size={18} />,
        isVisible: ({ organizationFeatures }) =>
          Boolean(organizationFeatures?.engines !== false),
        dataCy: 'settings-sidebar-engines-link',
        translationKey: 'automation.aiEngines',
        isActive: (_, location) =>
          location.pathname.includes(automationEnginesPath()),
      }),
    ],
  }),
  tab({
    name: 'extensions',
    roles: ['admin', 'organization_group_admin'],
    icon: <ExtensionOutlined />,
    link: '/settings/store',
    menu: [
      menu({
        name: 'rossumStore',
        link: '/settings/store',
        icon: <StoreMallDirectoryRounded sx={{ width: 18, height: 18 }} />,
        dataCy: 'settings-sidebar-store-link',
        translationKey: 'extensions.rossumStore',
      }),
      menu({
        name: 'myExtensions',
        link: '/settings/extensions',
        icon: <GoogleCirclesExtendedIcon size={18} />,
        dataCy: 'settings-sidebar-extensions-link',
        translationKey: 'extensions.myExtensions',
        // exclude `/extensions/logs` route from active match for this nav item
        isActive: (match, location) =>
          (!!match &&
            // TODO: the check can be removed when we change routes structure
            // and extensions list is nested on its own route, eg. `/extensions/my-extensions`
            !location.pathname.includes('/extensions/logs')) ||
          !!location.pathname.match(/\/extensions\/(\d+)/),
      }),
      menu({
        name: 'logs',
        link: '/settings/extensions/logs',
        icon: <ManageSearch fontSize="small" />,
        dataCy: 'settings-sidebar-extensions-logs',
        translationKey: 'extensions.logs',
      }),
    ],
  }),
  tab({
    name: 'statistics',
    roles: ['admin', 'organization_group_admin', 'manager'],
    icon: <StatisticsIcon />,
    link: () => {
      const items = parse(localStorage.getItem(STATISTICS_QUERY) || '');

      const validItems = pick(items, [
        'beginDate',
        'endDate',
        'groupBy',
        'queues',
        'users',
      ]);

      return !isEmpty(validItems)
        ? `/statistics?${stringify(validItems)}`
        : '/statistics';
    },
  }),
  tab({
    name: 'settings',
    roles: ['admin', 'organization_group_admin'],
    icon: <Settings />,
    link: '/settings',
    menu: [
      menu({
        name: 'users',
        link: '/settings/users',
        icon: (
          <SvgIcon
            fontSize="small"
            sx={{ fill: 'none', color: 'white', opacity: 0.4 }}
            component={IconUserSquareRounded}
          />
        ),
        dataCy: 'settings-users-link',
        translationKey: 'settings.users',
      }),
      menu({
        name: 'fieldManager',
        link: '/settings/field-manager',
        icon: (
          <SvgIcon
            fontSize="small"
            sx={{ fill: 'none', color: 'white', opacity: 0.4 }}
            component={IconGoGame}
          />
        ),
        isVisible: ({ customFeatures }) => customFeatures.isFieldManagerEnabled,
        dataCy: 'settings-field-manager-link',
        translationKey: 'settings.fieldManager',
      }),
      menu({
        name: 'labels',
        link: '/settings/labels',
        icon: (
          <SvgIcon
            fontSize="small"
            sx={{ fill: 'none', color: 'white', opacity: 0.4 }}
            component={IconTag}
          />
        ),
        isVisible: ({ customFeatures }) => customFeatures.isLabelsEnabled,
        dataCy: 'settings-labels-link',
        translationKey: 'settings.labels',
      }),
    ],
  }),
];

export type Tab = (typeof tabs)[number];
type TabName = (typeof tabs)[number]['name'];
type TabWithMenu = HasMenu<Tab>;
export type TabWithMenuName = TabWithMenu['name'];
type MenuItem = Extract<(typeof tabs)[number]['menu'], Array<unknown>>[number];

export const tabByName = <N extends TabName>(
  tabName: N
): Extract<Tab, { name: N }> =>
  tabs.find(t => t.name === tabName) as Extract<Tab, { name: N }>;

export const tabToLink = (tab: Tab, linkContext?: LinkContext) =>
  typeof tab.link === 'string' ? tab.link : tab.link(linkContext);

export const isTabVisible = (tab: Tab, role: GroupRole) =>
  tab.roles === undefined || tab.roles.includes(role);

export const visibleMenuItemsForTab = (
  tab: Tab,
  context: VisibleMenuItemsForTabContext
) =>
  tab.menu === undefined
    ? []
    : (tab.menu as MenuItem[]).filter(
        m => m.isVisible === undefined || m.isVisible(context)
      );

export const stripQuery = (url: string) => {
  const startOfQuery = url.indexOf('?');

  return startOfQuery === -1 ? url : url.slice(0, startOfQuery);
};

export const isTabSelected = (tab: Tab, location: Location<unknown>) => {
  if (tab.isSelected) {
    // Custom selection logic has priority
    return tab.isSelected(location);
  }

  // Check if the tab or any of the menu items referes to the current path.
  // We ignore query part of the URL, since it usually refers to last parameters used on the target page,
  // and it shouldn't be part of the decision.
  if (location.pathname.startsWith(stripQuery(tabToLink(tab)))) {
    return true;
  }

  if (tab.menu) {
    return tab.menu.some(m => location.pathname.startsWith(stripQuery(m.link)));
  }

  return false;
};

export const defaultTab = tabByName('documents');
