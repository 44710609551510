import { Stack } from '@rossum/ui/material';
import { ReactNode, useRef } from 'react';
import Scrollable from '../Scrollable';

const CONTAINER_WIDTH = 1024;

type PageLayoutV2Props = {
  children: ReactNode;
  renderHeader?: (params: {
    scrollableDivRef: React.RefObject<HTMLDivElement>;
  }) => ReactNode;
  renderFooter?: () => ReactNode;
  fullWidth?: boolean;
};

export const PageLayoutV2 = ({
  children,
  renderHeader,
  renderFooter,
  fullWidth,
}: PageLayoutV2Props) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  return (
    <Scrollable ref={scrollableDivRef}>
      <Stack
        justifyContent="space-between"
        sx={{
          minHeight: '100%',
          backgroundColor: t => t.palette.background.default,
        }}
      >
        <Stack>
          {renderHeader?.({ scrollableDivRef }) ?? null}
          <Stack width={fullWidth ? 1 : CONTAINER_WIDTH} mx="auto">
            {children}
          </Stack>
        </Stack>
        {renderFooter ? (
          <Stack
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: t => t.palette.background.default,
            }}
          >
            {renderFooter()}
          </Stack>
        ) : null}
      </Stack>
    </Scrollable>
  );
};
