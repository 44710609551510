import { Button, Dialog, Stack, Tab, Tabs } from '@rossum/ui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useResetPasswordByAdmin } from '../../../business/users/useResetPasswordByAdmin';
import { getName } from '../../../redux/modules/user/helpers';
import { SSO } from '../../../redux/modules/users/values';
import { HelmetComponent } from '../../../routes/HelmetComponent';
import { OrganizationUser, User } from '../../../types/user';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { SettingsBreadcrumbs } from '../../Settings/SettingsBreadcrumbs';
import DeleteUserDialog from '../../Users/components/DeleteUser/DeleteUserDialog';
import ResetUserPasswordDialog from '../../Users/components/ResetUserPasswordDialog';
import { userDetailsBasePath, usersPath } from '../../Users/helpers';

const USER_PAGES_KEYS = ['access', 'info'] as const;

type OpenDialog = 'deleteAccount' | 'resetPassword' | null;

type UserHeaderProps = {
  pageKey: (typeof USER_PAGES_KEYS)[number];
  user: OrganizationUser | User;
  toggle: ReactNode;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const UserHeader = ({
  pageKey,
  user,
  toggle,
  ...rest
}: UserHeaderProps) => {
  const [showDialog, setShowDialog] = useState<OpenDialog>(null);

  const intl = useIntl();
  const history = useHistory();

  const { id } = user;
  const userName = getName(user) ?? user.username;

  const { mutate } = useResetPasswordByAdmin();

  const tabs = (
    <Tabs
      value={pageKey}
      onChange={(_, value) => {
        history.push(`${userDetailsBasePath(id)}/${value}`);
      }}
    >
      {USER_PAGES_KEYS.map(tab => (
        <Tab
          key={tab}
          label={intl.formatMessage({
            id: `containers.settings.users.${tab}`,
          })}
          data-cy={`user-header-tab-${tab}`}
          value={tab}
        />
      ))}
    </Tabs>
  );

  const getDialogContent = (open: OpenDialog) => {
    switch (open) {
      case 'deleteAccount':
        return (
          <DeleteUserDialog
            user={user}
            onDelete={() => history.push(usersPath())}
            onCancel={() => setShowDialog(null)}
          />
        );
      case 'resetPassword':
        return (
          <ResetUserPasswordDialog
            userName={userName}
            onReset={() =>
              mutate({
                email: user.email ?? user.username,
              })
            }
            onCancel={() => setShowDialog(null)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <HelmetComponent
        dynamicName={userName}
        translationKey="features.routes.pageTitles.settings.user"
      />
      <Header
        {...rest}
        breadcrumbs={
          <SettingsBreadcrumbs
            breadcrumbs={[
              {
                to: usersPath(),
                label: intl.formatMessage({
                  id: 'containers.settings.users.header.title',
                }),
              },
              {
                label: intl.formatMessage({
                  id: `containers.settings.users.${pageKey}`,
                }),
              },
            ]}
          />
        }
        tabs={tabs}
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            {intl.formatMessage({
              id: `containers.settings.users.user.${pageKey}`,
            })}
          </Stack>
        }
        description={
          <Stack direction="row" alignItems="center" gap={1}>
            {userName}
            {toggle}
          </Stack>
        }
        buttons={[
          user?.authType !== SSO ? (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setShowDialog('resetPassword')}
              key="reset-password"
            >
              {intl.formatMessage({
                id: 'containers.settings.users.user.resetPassword',
              })}
            </Button>
          ) : null,
          <Button
            variant="contained"
            onClick={() => setShowDialog('deleteAccount')}
            key="delete-account"
          >
            {intl.formatMessage({
              id: 'containers.settings.users.user.deleteAccount',
            })}
          </Button>,
        ]}
        onBackButtonClicked={() => history.push(usersPath())}
      />
      <Dialog
        open={!!showDialog}
        PaperProps={{
          sx: { width: 480 },
          elevation: 2,
        }}
        onClose={() => setShowDialog(null)}
      >
        {getDialogContent(showDialog)}
      </Dialog>
    </>
  );
};
